import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import SimpleBackdrop from "../../components/Backdrop";
import { errorMessage, successMessage } from "../../helpers/Message";
import instance from "../../services/apiServices/Api";
import Storage from "../../services/local_storage/localStorage";
import * as Yup from "yup";
import {
  Box,
  Container,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Switch,
  Paper,
  Divider,
  Button,
  Chip,
  Stack,
  ThemeProvider,
  createTheme,
  Fade,
  useMediaQuery,
  Checkbox
} from "@mui/material";
import CustomizedHook from "../../components/customHooks/CustomizedHook";
import { AppTexts } from "../../constants/AppTexts";
import { AppColors } from "../../constants/AppColors";
import { styled } from "@mui/material/styles";
import AppCustomButton, { buttonSizes, buttonVariants } from "../../components/AppCustomButton";

// Custom styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 24,
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.06)",
  background: "#ffffff",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.08)",
  },
}));

const StyledSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  "& .MuiFormLabel-root": {
    marginBottom: theme.spacing(2),
    color: "#1a1a1a",
    fontWeight: 600,
    fontSize: "1rem",
    transition: "color 0.2s ease",
  },
  "&:hover .MuiFormLabel-root": {
    color: theme.palette.primary.main,
  },
}));

const StyledTagsInput = styled(Box)(({ theme }) => ({
  "& .rti--container": {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "12px",
    minHeight: "56px",
    padding: "8px 12px",
    transition: "all 0.2s ease",
    backgroundColor: "#ffffff",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&:focus-within": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    },
  },
  "& .rti--input": {
    backgroundColor: "transparent",
    padding: "4px 8px",
    margin: "0",
    fontSize: "16px",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  "& .rti--tag": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "20px",
    padding: "6px 16px",
    margin: "4px",
    fontSize: "14px",
    fontWeight: 500,
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    transition: "all 0.2s ease",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    backgroundColor: "#ffffff",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      backgroundColor: "#ffffff",
    },
    "&.Mui-focused": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
      backgroundColor: "#ffffff",
    },
    "& fieldset": {
      borderWidth: "0 !important",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "16px",
  },
}));

const StyledFormLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: "#1a1a1a",
  fontWeight: 600,
  fontSize: "1rem",
  transition: "color 0.2s ease",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "12px",
  padding: "12px 36px",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  boxShadow: "none",
  transition: "all 0.2s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 16px rgba(0, 0, 0, 0.15)",
  },
  "&:active": {
    transform: "translateY(0)",
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  "&.Mui-checked": {
    transform: "scale(1.1)",
    transition: "transform 0.2s ease",
  },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(20px)",
    transition: "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  },
}));

function GalleryEditDownload() {
  const { id } = useParams();
  const { state } = useLocation();
  const token = Storage.getToken("token");
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [releaseList, setReleaseList] = useState([]);
  const [releaseInfo, setReleaseInfo] = useState([]);
  const [isAIGenerated, setIsAIGenerated] = useState(false);
  const [isFictional, setIsFictional] = useState(false);
  const navigate = useNavigate();

  // Create custom theme
  const theme = createTheme({
    palette: {
      primary: {
        main: AppColors.PrimaryColor,
      },
    },
    typography: {
      h4: {
        fontWeight: 600,
        marginBottom: "32px",
      },
      subtitle1: {
        fontWeight: 500,
        color: "#666",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = (id) => {
    setLoading(true);
    instance
      .post("/contributor/downloads", { download_id: id, type: state.type }, header)
      .then((res) => {
        setLoading(false);
        setReleaseList(res.data.releases);
        for (let item in formik.initialValues) {
          if ([item] in res.data.data) {
            formik.setFieldValue(item, res.data.data[item] === null ? "" : res.data.data[item]);
          }
          if (["is_recognized"].includes(item)) {
            formik.setFieldValue("is_recognized", res.data.data.is_recognized ? "1" : "0");
          }
          if (["is_aigenerated"].includes(item)) {
            formik.setFieldValue("is_aigenerated", res.data.data.is_aigenerated ? "1" : "0");
            setIsAIGenerated(res.data.data.is_aigenerated === 1);
          }
        }
        formik.setFieldValue(
          "release_id",
          res.data.data.release.length <= 0
            ? []
            : res.data.data.release.map((item) => item.release_id)
        );
        setSelected(res.data.data.tags.map((item) => item.name));
        setReleaseInfo(res.data.data.release.map((item) => item.release_info));
        setIsAIGenerated(res.data.data.is_aigenerated === 1);
      })
      .catch((err) => {
        setLoading(false);
        errorMessage("Error", err.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      download_id: [id],
      title: "",
      description: "",
      media_type: "creative",
      is_recognized: "0",
      is_aigenerated: "0",
      submit_for_approval: state.submit_for_approval,
      release_id: [],
      tags: [],
      is_aigenerated: "0",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is required")
        .max(255, "Title must be less than 255 characters"),
      description: Yup.string()
        .required("Description is required")
        .max(250, "Description must be less than 250 characters"),
      media_type: Yup.string().required("Media type is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      if (values.is_aigenerated === "1" && !isFictional) {
        setLoading(false);
        return errorMessage(AppTexts.Error, AppTexts.CheckPeopleAreFictional);
      }

      if (selected.length >= 6) {
        if (values.is_recognized === "1" && (!values.release_id || values.release_id.length <= 0)) {
          setLoading(false);
          return errorMessage("Error", "Please select a release form");
        }

        let data = { ...values, tags: selected };
        instance
          .post("/contributor/downloads/update", data, header)
          .then((res) => {
            setLoading(false);
            successMessage("Success", "Updated successfully").then(() => {
              navigate("/contributor-dashboard/gallery");
            });
            resetForm();
            setSelected([]);
          })
          .catch((error) => {
            setLoading(false);
            errorMessage("Error", error.response?.data?.message || "Something went wrong");
          });
      } else {
        setLoading(false);
        errorMessage("Error", "Minimum 6 keywords required");
      }
    },
  });

  const handleTagChange = (newTags) => {
    const processedTags = newTags
      .flatMap(tag => tag.split(',').map(subTag => subTag.trim()))
      .filter(tag => tag !== "");
    setSelected(processedTags);
  };

  const multiSearch = (newInputValue) => {
    const release_ids = newInputValue.map((re) => re.id);
    formik.setFieldValue("release_id", release_ids);
  };

  const handleAIGeneratedChange = (event) => {
    const isGenerated = event.target.checked;
    setIsAIGenerated(isGenerated);
    formik.setFieldValue("is_aigenerated", isGenerated ? "1" : "0");
  };

  const handleSubmit = (submitForApproval) => {
    formik.setFieldValue("submit_for_approval", submitForApproval ? 1 : 0);
    formik.handleSubmit();
  };

  return (
    <ThemeProvider theme={theme}>
      {loading && <SimpleBackdrop />}
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Fade in timeout={800}>
          <Box>
            <Typography
              variant="h4"
              component="h1"
              align="center"
              sx={{
                mb: 4,
                fontWeight: 700,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Edit Download Details
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <StyledPaper elevation={0}>
                <Stack spacing={5}>
                  {/* Title Section */}
                  <StyledSection>
                    <StyledFormLabel>Title</StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter title"
                      {...formik.getFieldProps("title")}
                      error={formik.touched.title && Boolean(formik.errors.title)}
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </StyledSection>

                  {/* Keywords Section */}
                  <StyledSection>
                    <StyledFormLabel>Keywords (Minimum 6 required)</StyledFormLabel>
                    <StyledTagsInput>
                      <TagsInput
                        value={selected}
                        onChange={handleTagChange}
                        name="tags"
                        separators={["Enter", ","]}
                        placeHolder="Type and press Enter to add keywords"
                      />
                    </StyledTagsInput>
                  </StyledSection>

                  {/* Description Section */}
                  <StyledSection>
                    <StyledFormLabel>Description</StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      placeholder="Enter description"
                      {...formik.getFieldProps("description")}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </StyledSection>

                  {/* Media Type Section */}
                  <StyledSection>
                    <FormControl component="fieldset">
                      <StyledFormLabel>Media Type</StyledFormLabel>
                      <RadioGroup
                        row
                        {...formik.getFieldProps("media_type")}
                        sx={{ gap: 4, mt: 1 }}
                      >
                        <FormControlLabel
                          value="editorial"
                          control={<StyledRadio color="primary" />}
                          label="Editorial"
                        />
                        <FormControlLabel
                          value="creative"
                          control={<StyledRadio color="primary" />}
                          label="Creative"
                        />
                      </RadioGroup>
                    </FormControl>
                  </StyledSection>

                  <Divider sx={{
                    my: 2,
                    "&::before, &::after": {
                      borderColor: "primary.light",
                    },
                  }} />

                  {/* Recognizable People Section */}
                  <StyledSection>
                    <FormControl component="fieldset">
                      <StyledFormLabel>{AppTexts.RecognizablePeople}</StyledFormLabel>
                      <RadioGroup
                        row
                        value={formik.values.is_recognized}
                        onChange={(e) => {
                          formik.setFieldValue("is_recognized", e.target.value);
                          formik.setFieldValue("release_id", []);
                        }}
                        sx={{ gap: 4, mt: 1 }}
                      >
                        <FormControlLabel
                          value="1"
                          control={<StyledRadio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="0"
                          control={<StyledRadio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>

                    {formik.values.is_recognized === "1" && (
                      <Fade in timeout={500}>
                        <Box sx={{ mt: 3 }}>
                          {releaseInfo?.id || formik.values.is_recognized === "1" ? (
                            <CustomizedHook
                              dataList={releaseList}
                              setData={multiSearch}
                              selectedList={releaseInfo}
                            />
                          ) : null}

                          {!formik.values.release_id?.length && (
                            <Box sx={{
                              mt: 4,
                              textAlign: "center",
                              p: 4,
                              borderRadius: 3,
                              bgcolor: "rgba(0, 0, 0, 0.02)",
                            }}>
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                sx={{
                                  fontWeight: 500,
                                  color: "text.primary"
                                }}
                              >
                                Need a new release form?
                              </Typography>
                              <Link
                                to="/contributor-dashboard/release-form/create"
                                state={{ url: "/contributor-dashboard/listing", type: state?.type }}
                                style={{ textDecoration: "none" }}
                              >
                                <StyledButton
                                  variant="outlined"
                                  color="primary"
                                  sx={{ mt: 2 }}
                                >
                                  Create Release Form
                                </StyledButton>
                              </Link>
                            </Box>
                          )}
                        </Box>
                      </Fade>
                    )}
                  </StyledSection>

                  <Divider sx={{
                    my: 2,
                    "&::before, &::after": {
                      borderColor: "primary.light",
                    },
                  }} />

                  {/* AI Generated Section */}
                  <StyledSection>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={isAIGenerated}
                            onChange={handleAIGeneratedChange}
                            color="primary"
                          />
                        }
                        label={
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 500,
                              color: "text.primary"
                            }}
                          >
                            {AppTexts.isAIGeneratedText}
                          </Typography>
                        }
                      />
                    </FormControl>

                    {isAIGenerated && (
                      <Fade in timeout={500}>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 2,
                              fontSize: "14px",
                              color: "text.secondary",
                              p: 2,
                              borderRadius: 2,
                              bgcolor: "rgba(0, 0, 0, 0.02)",
                            }}
                          >
                            {AppTexts.AiNoteTerms}
                          </Typography>
                          
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isFictional}
                                onChange={(e) => setIsFictional(e.target.checked)}
                                color="primary"
                              />
                            }
                            label="People and Property are fictional"
                            sx={{ mt: 2 }}
                          />
                          
                          {isFictional && (
                            <Typography
                              variant="body2"
                              sx={{
                                mt: 1,
                                fontSize: "14px",
                                color: "text.secondary",
                              }}
                            >
                              {AppTexts.AiTermsCertify}
                            </Typography>
                          )}
                        </Box>
                      </Fade>
                    )}
                  </StyledSection>

                  {/* Submit Button */}
                  <Box sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 4,
                    gap: 2
                  }}>
                    <AppCustomButton
                      size={buttonSizes.LARGE}
                      variant={buttonVariants.SECONDARY}
                      onClick={() => handleSubmit(false)}
                      type="button"
                    >
                      Save Work
                    </AppCustomButton>

                    <AppCustomButton
                      size={buttonSizes.LARGE}
                      variant={buttonVariants.PRIMARY}
                      onClick={() => handleSubmit(true)}
                      type="button"
                    >
                      Submit for Approval
                    </AppCustomButton>
                  </Box>
                </Stack>
              </StyledPaper>
            </form>
          </Box>
        </Fade>
      </Container>
    </ThemeProvider>
  );
}

export default GalleryEditDownload;
