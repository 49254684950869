import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { AppColors } from '../constants/AppColors';

const FreeTrialBanner = ({ isSticky }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const shouldBeFullHeight = isHomePage && !isSticky;

  return (
    <Box sx={{ 
      bgcolor: AppColors.PrimaryColor,
      width: '100%',
      py: shouldBeFullHeight ? { xs: 4, md: 4.5 } : { xs: 1, md: 1.5 },
      position: 'relative',
      zIndex: 10000,
      '& + .navbar.sticky-header': {
        top: '0 !important'
      }
    }}>
      <Container sx={{ 
        height: '100%',
        maxWidth: '100% !important',
        px: { xs: 2, md: 3 }
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          gap: { xs: 2, md: 3 },
          flexDirection: { xs: 'column', md: 'row' },
          height: '100%'
        }}>
          <Typography 
            variant="h6" 
            component="span" 
            sx={{ 
              color: 'white',
              fontSize: shouldBeFullHeight ? { xs: '16px', md: '18px' } : { xs: '14px', md: '16px' },
              textAlign: 'center',
              lineHeight: 1.2
            }}
          >
            Discover the Power of Visual Storytelling with Weaving Stories
          </Typography>
          <Button
            component="a"
            href="https://weavingstoriesbook.com"
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            sx={{
              color: 'white',
              borderColor: 'white',
              borderRadius: '25px',
              padding: shouldBeFullHeight ? '6px 20px' : '4px 16px',
              minHeight: 0,
              fontSize: shouldBeFullHeight ? '16px' : '14px',
              whiteSpace: 'nowrap',
              '&:hover': {
                bgcolor: 'white',
                color: '#FF69B4',
                borderColor: 'white'
              }
            }}
          >
            Pre-Order Now!
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default FreeTrialBanner;
