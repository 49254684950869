import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@mui/material/Typography";
import { onCall } from "../../redux/slices/fileSlice";
import SimpleBackdrop from "../../components/Backdrop";
import instance from "../../services/apiServices/Api";
import { errorMessage, successMessage } from "../../helpers/Message";
import Storage from "../../services/local_storage/localStorage";
import { useDispatch, useSelector } from "react-redux";
import video_placeholder from "../../assets/video.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";


function AddImage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { downloadPercentage } = useSelector((item) => item.fileDownload);
  const { id } = Storage.getUserData("userData");
  const token = Storage.getToken("token");
  var randomstring = require("randomstring");
  const [videoProgress, setVideoProgress] = useState(0);
  const [preview, setpreview] = useState([]);
  const [draftCount, setDraftCount] = useState(0);
  const [mediaType, setMediaType] = useState("image");
  const [loading, SetLoading] = useState(false);
  const [hideRules, setHideRules] = useState(false);
  const fileInput = React.useRef();
  const date = new Date();
  let s3Urls = [];
  let errCount = 0;
  const [progress, setProgress] = useState({});

  const getData = useCallback(() => {
    instance
      .post(
        "contributor/draft-count",
        { type: mediaType },
        {
          headers: {
            Authorization: `Bearer ${Storage.getToken("token")}`,
          },
        }
      )
      .then((res) => {
        setDraftCount(res.data.data.draft_count);
      });
  }, [mediaType]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleClick = (event) => {
    event.preventDefault();
    SetLoading(true);
    setHideRules(true);
    errCount = 0;
    let newArr = fileInput.current.files;
    for (let i = 0; i < newArr.length; i++) {
      const code = randomstring.generate({
        length: 16,
        charset: "alphanumeric",
      });
      const extention = newArr[i].name.slice(newArr[i].name.lastIndexOf("."));
      const myNewFile = new File(
        [newArr[i]],
        `${code}${date.getTime()}${i}${extention}`,
        { type: newArr[i].type }
      );
      validate_image(myNewFile, i, newArr.length);
    }
  };

  const validate_image = (file, index, length) => {
    if (mediaType === "image") {
      if (file.type && !file.type.startsWith("image/")) {
        SetLoading(false);
        setHideRules(false);
        toast.error("Invalid File Type ");
        return;
      }
      // const reader = new FileReader();
      // reader.addEventListener("load", (event) => {
      var image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = function () {
        if (this.width >= 2464 || this.height >= 2464) {
          if (file.size > 47185920) {
            SetLoading(false);
            toast.error("File size should be less then 45 MB", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            errCount = errCount + 1;
          } else {
            uploadFile(file, index, length);
          }
        } else {
          SetLoading(false);
          toast.error("Image height or width Must be more than 2464px", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          errCount = errCount + 1;
        }
      };
      image.onerror = () => {
        SetLoading(false);
        toast.error("Image is invaild", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        errCount = errCount + 1;
      };
      // });
      // reader.readAsDataURL(file);
    } else {
      if (file.type && !file.type.startsWith("video/")) {
        SetLoading(false);
        setHideRules(false);
        toast.error("Invalid File Type ");
        return;
      }
      if (file.size <= 4089446400) {
        uploadVideo(file, index, length);
      } else {
        SetLoading(false);
        setHideRules(false);
        toast.error("File size should be less then 3.9gb", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        errCount = errCount + 1;
      }
    }
  };

  const uploadFile = async (file, index, arrLength) => {
    SetLoading(true);
    resizeFile(file);
    var newFile = file;

    const target = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_IMAGE,
      Key: `${id}/original/images/${newFile.name}`,
      Body: newFile,
      ContentType: file.type,
    };

    let credentials = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      AccelerateConfiguration: {
        /* required */ Status: "Enabled",
      },
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({
          BucketAccelerateStatus: "Enabled",
          region: process.env.REACT_APP_AWS_REGION,
          credentials: credentials,
        }),
        params: target,
        leavePartsOnError: false, // optional manually handle dropped parts
        correctClockSkew:true
      }).on("httpUploadProgress", (progress) => {
        const { loaded, total } = progress;

        setProgress((prev) => {
          return {
            ...prev,
            [file.name]: Math.round((100 * loaded) / total),
          };
        });
      });

      const response = await parallelUploads3.done();
      s3Urls.push(
        `https://${process.env.REACT_APP_AWS_BUCKET_IMAGE}.s3.amazonaws.com/${response.Key}`
      );
      if (s3Urls.length + errCount === arrLength) {
        SaveData();
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const uploadVideo = async (file, index, arrLength) => {
    SetLoading(true);
    var newFile = file;

    const target = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_VIDEO,
      Key: `${id}/original/videos/${newFile.name}`,
      Body: newFile,
      ContentType: file.type,
    };

    let credentials = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      AccelerateConfiguration: {
        /* required */ Status: "Enabled",
      },
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({
          BucketAccelerateStatus: "Enabled",
          region: "us-east-1",
          credentials: credentials,
        }),
        params: target,
        leavePartsOnError: false, // optional manually handle dropped parts
      }).on("httpUploadProgress", (progress) => {
        SetLoading(false);
        setVideoProgress(Math.round((100 * progress.loaded) / progress.total));

        dispatch(
          onCall({
            downloadPercentage: Math.round(
              (100 * progress.loaded) / progress.total
            ),
            isDownloading:
              Math.round((100 * progress.loaded) / progress.total) === 100
                ? false
                : true,
            uploadFunction: parallelUploads3,
          })
        );
      });
      const response = await parallelUploads3.done();

      s3Urls.push(
        `https://${process.env.REACT_APP_AWS_BUCKET_VIDEO}.s3.amazonaws.com/${response.Key}`
      );

      if (s3Urls.length + errCount === arrLength) {
        SaveData();
      }
    } catch (e) {
      SetLoading(false);
    }
  };

  const SaveData = () => {
    SetLoading(true);
    const data = { type: mediaType, file_url: s3Urls };
    instance
      .post("/contributor/downloads/create", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        SetLoading(false);
        if (s3Urls.length > 1) {
          successMessage(
            "Thanks!",
            `All ${window.capitalizeFirst(
              mediaType
            )}s has been uploaded successfully!`
          ).then(
            () => navigate("/contributor-dashboard/multiple-upload"),
            getData()
          );
        } else {
          successMessage(
            "Thanks!",
            `${window.capitalizeFirst(
              mediaType
            )} has been uploaded successfully!`
          ).then(
            () => navigate("/contributor-dashboard/multiple-upload"),
            getData()
          );
        }
      })
      .catch((error) => {
        SetLoading(false);
        errorMessage("Something went wrong");
      });
  };

  const resizeFile = (file) => {
    var image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      ctx.imageSmoothingEnabled = false;
      canvas.width = image.width;
      canvas.height = image.width;

      ctx.drawImage(image, 0, 0, image.width, image.width);
      canvas.toBlob((blob) => {
        SetLoading(false);
        setpreview((item) => [
          ...item,
          { url: window.URL.createObjectURL(blob), fileName: file.name },
        ]);
      }, file.type);
    };
  };
  return (
    <>
      {loading && <SimpleBackdrop />}
      <ToastContainer position="top-right" />
      <h2 className="dashboard-title">Upload {mediaType}s</h2>

      {/* <MaintenanceCloseBanner message="Video uploads are temporarily unavailable. Our team is working on it, and for now, you can upload images instead." textColor={AppColors.WhiteColor}  severity="info" backgroundColor={AppColors.DarkBlue}  /> */}


      <div className="dashboard-imageupdate">
        {/* <p className="imageupdate-intro">{mediaType === 'image' ? "Image height and width must be more than 2464px, Can select only .png and .jpg files." : "Please select only mp4, mov video files."}</p> */}
        <div className="field-outer search-variation-outer">
          <div
            id="open-filter-variation"
            className="search-variation"
            onClick={() =>
              document
                .getElementById("open-filter-variation")
                .classList.toggle("open-filter-variation")
            }
          >
            {/* <label >Media Type :</label> */}
            <div className="inputField">
              {mediaType ? mediaType : "Select"}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M201.4 374.6c12.5 12.5 32.8 12.5 
                                                    45.3 0l160-160c12.5-12.5 12.5-32.8 
                                                    0-45.3s-32.8-12.5-45.3 0L224 306.7 
                                                    86.6 169.4c-12.5-12.5-32.8-12.5-45.3 
                                                    0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg>
            </div>
            <div className="search-variation-submenu ">
              <ul className="search-variation-list">
                <li onClick={() => setMediaType("image")}>Image</li>
                <li onClick={() => setMediaType("video")}>Video</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="imageupdate-uploading-area">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.2"
            viewBox="0 0 72 67"
            width="72"
            height="67"
          >
            <path
              id="Shape 12"
              className="s0"
              d="m21.2 54.2c-3.3-0.4-6.7-0.3-9.9-1.3-6-1.8-9.5-6.3-10.7-12.4-1.3-6.1 0.7-11.2 5.3-15.3 0.3-0.4 0.7-1.1 0.7-1.5-1.1-7.7 5.8-13.9 12.8-12.8 0.3 0 0.9-0.4 1.1-0.8 3.4-5 8.1-8.1 14.2-9.2 10.3-1.8 20.9 5.1 23.3 15.4 0.3 1.1 0.7 1.7 1.9 2.1 7.5 2.7 12.5 10.6 11.8 18.6-0.6 8.2-6.7 15.2-14.6 16.4-4.3 0.7-8.7 0.4-13 0.4-1.3 0-2-1-2-2.1 0.2-1.3 1-1.9 2.2-1.9 3.1-0.1 6.2-0.2 9.3 0 6.4 0.3 13.5-5 14.2-13.2 0.6-7.1-4.5-13.7-10.9-14.9-1.6-0.4-2.2-1.4-2.4-2.9-1.2-7.4-6.4-12.7-13.7-14-7-1.2-14 2.2-17.5 8.4-1.2 2.2-1.3 2.3-3.8 1.8-5.5-1.2-10.1 3.5-8.7 9.1 0.3 1.5 0 2.5-1.3 3.4-4.3 3-6.1 7.2-5 12.3 1 4.9 4 8.4 9.1 9.7 0.8 0.3 1.8 0.3 2.7 0.3 3.6 0 7.2 0 10.8 0 0.7 0 1.6 0.2 2 0.6 0.5 0.4 0.8 1.5 0.6 2-0.4 0.6-1.3 1.3-2 1.4-2.1 0.1-4.3 0-6.4 0q0 0.2-0.1 0.4zm16.8-22.2c0 0.9 0 1.4 0 1.9q0 15.2 0 30.3c0 1.8-0.6 2.6-1.9 2.6-1.2 0-1.9-0.6-2-1.7-0.1-0.4-0.1-0.8-0.1-1.3q0-14.9 0-29.9c0-0.5 0-1 0-1.9-1.1 1.1-1.9 1.9-2.8 2.8-1.4 1.4-2.8 2.8-4.2 4.2-0.9 0.9-2.1 0.9-2.9 0.1-0.8-0.8-0.9-1.9 0.1-2.8q5.1-5.3 10.3-10.4c1-0.9 2.1-0.9 3 0q5.2 5.2 10.3 10.3c1 1 0.9 2.2 0 3-0.8 0.7-1.9 0.7-2.8-0.2-2.3-2.2-4.5-4.5-7-7z"
            />
          </svg>
          <p>Drag and drop or browse to choose a file</p>
          {progress > 0 && progress < 100 && (
            <CircularProgress color="inherit" />
          )}
          <input
            type="file"
            accept={mediaType === "image" ? ".jpg, .png,.jpeg" : ".mp4,.mov"}
            ref={fileInput}
            onChange={(e) => handleClick(e)}
            multiple={mediaType === "image" ? true : false}
          />
        </div>

        {mediaType === "video" ? (
          <>
            <div className="dashboard-imageupdate-row">
              <div
                className={
                  videoProgress === 100
                    ? "dashboard-imageupdate-column"
                    : "dashboard-imageupdate-column imageupload-progress"
                }
              >
                <div className="dashboard-imageupdate-inner">
                  <img src={video_placeholder} alt="" />
                  {videoProgress > 0 && downloadPercentage > 0 && (
                    <>
                      <Box sx={{ width: "100%", padding: "20px" }}>
                        <LinearProgress
                          variant="buffer"
                          value={videoProgress}
                          valueBuffer={videoProgress + 10}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >{`${videoProgress}%`}</Typography>
                      </Box>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="dashboard-imageupdate-row">
            {preview &&
              preview.map((item, i) => (
                <div className="dashboard-imageupdate-column" key={i}>
                  <div
                    className={
                      progress[item?.fileName] === 100
                        ? "dashboard-imageupdate-inner"
                        : "dashboard-imageupdate-inner imageupload-progress"
                    }
                  >
                    <img src={item?.url} alt="" />
                    <Box sx={{ width: "100%", padding: "20px" }}>
                      <LinearProgress
                        variant="buffer"
                        value={progress[item?.fileName] ?? 0}
                        valueBuffer={progress[item?.fileName] ?? 0 + 10}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">{`${
                        progress[item?.fileName] ?? 0
                      }%`}</Typography>
                    </Box>
                  </div>
                </div>
              ))}
          </div>
        )}
        <div className="btn-action">
          {draftCount !== 0 && <button
            disabled={draftCount === 0}
            className="btn primary-btn btn-large"
            onClick={() =>
              navigate("/contributor-dashboard/listing", {
                state: { type: mediaType },
              })
            }
          >
            Next
          </button>}
          
        </div>
        <div
          hidden={draftCount === 0}
          className="dashboard-imageupdate-footer mb-5"
        >
          <p>{`You have ${draftCount} ${mediaType}s on your account waiting for you to add title/keywords and submit for approval.`}</p>
          <p>
            <Link
              state={{ type: mediaType }}
              to="/contributor-dashboard/listing"
            >
              Click here
            </Link>
            to access the images
          </p>
        </div>
        {mediaType === "image" ? (
          <div
            className="outer-row  uploadimages-inforow-outer"
            hidden={hideRules}
          >
            <div className="column-large-6">
              <div className="uploadimages-inforow">
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  JPEG format only
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  Minimum image resolution is 4MP
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  Maximum image resolution is 100MP
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  Maximum file size is 45MB
                </div>
              </div>
            </div>
            <div className="column-large-6">
              <div className="uploadimages-inforow">
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  <b>Original</b> content you captured
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  <b>Mindful</b> of the rights of others
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  <b>High quality</b> photos and videos
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  <b>Excludes</b> graphic nudity, violence, or hate
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="outer-row  uploadimages-inforow-outer"
            hidden={hideRules}
          >
            <div className="column-large-6">
              <div className="uploadimages-inforow">
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  Minimum video resolution is 1,280x720 but we recommend
                  submitting Full HD or 4K
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  File format must be either MOV, MP4
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  Unsupported File formats AVi, WMV, AVCHD, FLV, WebM
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  We prefer the following codecs: h264, ProRes, PNG
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  Duration needs to be at least 5 seconds and not exceed 60
                  seconds
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  Maximum file size is 3,900MB (3.9GB)
                </div>
              </div>
            </div>
            <div className="column-large-6">
              <div className="uploadimages-inforow">
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  <b>Original</b> content you captured
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  <b>Mindful</b> of the rights of others
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  <b>High quality</b> photos and videos
                </div>
                <div className="uploadimages-infowrap">
                  <FontAwesomeIcon
                    className="m-half-width"
                    icon={faCheckCircle}
                  />
                  <b>Excludes</b> graphic nudity, violence, or hate
                </div>
               
              </div>
            </div>
          </div>
        )}

      
      </div>
    </>
  );
}

export default AddImage;
