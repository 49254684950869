import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import Storage from "../services/local_storage/localStorage";
import { HomeConstants } from "../constants/HomePageConstants";
import { useFormik } from "formik";
import { errorMessage, successMessage } from "../helpers/Message";
import instance from "../services/apiServices/Api";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";

function CustomFooter() {
  const FooterReduxData = useSelector((state) => state.menus.dataOfFooter);
  const FooterData =
    FooterReduxData?.about_picha?.items?.length > 0
      ? FooterReduxData
      : Storage.getFooterData();
  const { social_media } = HomeConstants.customFooter;
  // const [loading, setLoading] = useState(false);
  const token = Storage.getToken();
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const isAuth = useSelector((item) => item.isAuth.value);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      captcha: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email").required("Email is Required"),
      captcha: Yup.boolean()
        .required("The Captcha must be accepted.")
        .oneOf([true], "The Captcha must be accepted."),
    }),
    onSubmit: (values) => {
      // setLoading(true);
      if (isAuth) {
        instance
          .post("/newsletter/subscribe", values, header)
          .then((res) => {
            // setLoading(false);
            if (res.data.response === 200) {
              formik.resetForm();
              successMessage("Success", res.data.message);
            }
          })
          .catch((error) => {
            // setLoading(false);
            errorMessage("Error", error.response.data.message);
          });
      } else {
        // setLoading(false);
        errorMessage("Error", "Login To Continue").then(() => {
          navigate("/login");
        });
      }
    },
  });
  return (
    <>
      <section className="footer">
        <div className="container">
          <div className="footer--main">
            <div className="footer--main-menu">
              <h3>{FooterData?.about_picha?.name}</h3>
              <ul>
                {FooterData?.about_picha?.items.map((item, i) => {
                  return (
                    <li key={i}>
                      {item.target_blank === 1 ? (
                        <Link
                          to="#"
                          onClick={(e) =>
                            window.open(item?.slug, "_blank", "noreferrer")
                          }
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <Link to={`/${item?.slug}`}>{item.name}</Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer--main-menu">
              <h3>{FooterData?.more_links?.name}</h3>
              <ul>
                {FooterData?.more_links?.items.map((item, i) => {
                  return (
                    <li key={i}>

{item.target_blank === 1 ? (
                        <Link
                          to="#"
                          onClick={(e) =>
                            window.open(item?.slug, "_blank", "noreferrer")
                          }
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <Link to={`/${item?.slug}`}>{item.name}</Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer--main-menu">
              <h3>{FooterData?.no_solutions?.name}</h3>
              <ul>
                {FooterData?.no_solutions?.items.map((item, i) => {
                  return (
                    <li key={i}>
                      {
                        // item.name === "Podcast" ? <Link to="#" onClick={(e) => (window.location = item?.slug)}>{item.name}</Link> :
                        <Link to={`/${item?.slug}`}>{item.name}</Link>
                      }
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer--main-menu">
              <h3>{FooterData?.photographers?.name}</h3>
              <ul>
                {FooterData?.photographers?.items.map((item, i) => {
                  return (
                    <li key={i}>
                      <Link to={`/${item?.slug}`}>{item.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer--main-menu">
              <h3>Join our community today</h3>
              <p>Sign-up for monthly creative content ideas.</p>
              <form onSubmit={formik.handleSubmit}>
                <div className="field-outer m-0">
                  <input
                    className="inputField"
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    {...formik.getFieldProps("email")}
                    value={formik.values.email ?? ""}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <div className="validation-error">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY_ID}
                  className="field-outer footerCaptcha"
                  onChange={() => formik.setFieldValue('captcha',true)}
                />
                {formik.errors.captcha && formik.touched.captcha ? (
                    <div className="validation-error mb-3 paypal-wrap">
                      {formik.errors.captcha}
                    </div>
                  ) : null}
                <div className="field-outer">
                  <button
                    className="btn primary-btn footer--main-menu-btn btn-large"
                    type="submit"
                  >
                    SUBSCRIBE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer--copyright">
        <p>Copyright {new Date().getFullYear()} PICHAstock Studio, All rights reserved</p>
          <div className="footer--copyright-icons">
            <ul>
              {social_media?.map((item, i) => {
                return (
                  <li key={i}>
                    <a
                      href={`${item?.link}`}
                      target="_blank"
                      rel="noreferrer"
                      className="social-icon"
                      area-label={`${item?.className}`}
                    >
                      <FontAwesomeIcon icon={item.icon} />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomFooter;
