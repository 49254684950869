import React from 'react';
import { Box, Button as MuiButton } from '@mui/material';
import { AppColors } from '../constants/AppColors';

export const buttonSizes = {
  FULL_LENGTH: 'fullLength',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  XSMALL: 'xsmall',
};

export const buttonVariants = {
  PRIMARY: 'primary',
  PRIMARY_OUTLINE: 'primaryOutline',
  SECONDARY: 'secondary',
  SECONDARY_OUTLINE: 'secondaryOutline',
  DANGER: 'danger',
  DANGER_OUTLINE: 'dangerOutline',
  SUCCESS: 'success',
  SUCCESS_OUTLINE: 'successOutline',
  DISABLED: 'disabled',
  DARK: 'dark',
  DARK_OUTLINE: 'darkOutline',
};

const AppCustomButton = ({
  children,
  icon,
  variant = buttonVariants.PRIMARY,
  size = buttonSizes.MEDIUM,
  onClick,
  disabled,
  ...props
}) => {
  const styles = {
    // Primary Variants
    [buttonVariants.PRIMARY]: {
      backgroundColor: AppColors.PrimaryColor,
      color: '#fff',
      border: 'none',
      '&:hover': {
        backgroundColor: AppColors.PrimaryHoverColor,
      },
      '&:disabled': {
        backgroundColor: '#ccc',
        color: '#666',
      },
    },
    [buttonVariants.PRIMARY_OUTLINE]: {
      backgroundColor: 'transparent',
      color: AppColors.PrimaryColor,
      border: `1px solid ${AppColors.PrimaryColor}`,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderColor: AppColors.PrimaryHoverColor,
        color: AppColors.PrimaryHoverColor,
      },
      '&:disabled': {
        borderColor: '#ccc',
        color: '#666',
      },
    },

    // Secondary Variants
    [buttonVariants.SECONDARY]: {
      backgroundColor: AppColors.SecondaryColor || '#6c757d',
      color: '#fff',
      border: 'none',
      '&:hover': {
        backgroundColor: AppColors.SecondaryHoverColor || '#5a6268',
      },
      '&:disabled': {
        backgroundColor: '#ccc',
        color: '#666',
      },
    },
    [buttonVariants.SECONDARY_OUTLINE]: {
      backgroundColor: 'transparent',
      color: AppColors.SecondaryColor || '#6c757d',
      border: `1px solid ${AppColors.SecondaryColor || '#6c757d'}`,
      '&:hover': {
        backgroundColor: 'rgba(108, 117, 125, 0.04)',
        borderColor: AppColors.SecondaryHoverColor || '#5a6268',
        color: AppColors.SecondaryHoverColor || '#5a6268',
      },
      '&:disabled': {
        borderColor: '#ccc',
        color: '#666',
      },
    },


    // Dark Variants
    [buttonVariants.DARK]: {
      backgroundColor: AppColors.DarkPrimary || '#222B3D',
      color: '#fff',
      border: 'none',
      '&:hover': {
        backgroundColor: AppColors.DarkPrimaryHover || '#5a6268',
      },
      '&:disabled': {
        backgroundColor: '#ccc',
        color: '#666',
      },
    },
    [buttonVariants.DANGER_OUTLINE]: {
      backgroundColor: 'transparent',
      color: AppColors.DarkPrimary || '#222B3D',
      border: `1px solid ${AppColors.DarkPrimary || '#222B3D'}`,
      '&:hover': {
        backgroundColor: 'rgba(108, 117, 125, 0.04)',
        borderColor: AppColors.DarkPrimaryHover || '#5a6268',
        color: AppColors.DarkPrimaryHover || '#5a6268',
      },
      '&:disabled': {
        borderColor: '#ccc',
        color: '#666',
      },
    },

    // Danger Variants
    [buttonVariants.DANGER]: {
      backgroundColor: AppColors.DangerColor || '#dc3545',
      color: '#fff',
      border: 'none',
      '&:hover': {
        backgroundColor: '#c82333',
      },
      '&:disabled': {
        backgroundColor: '#ccc',
        color: '#666',
      },
    },
    [buttonVariants.DANGER_OUTLINE]: {
      backgroundColor: 'transparent',
      color: AppColors.DangerColor || '#dc3545',
      border: `1px solid ${AppColors.DangerColor || '#dc3545'}`,
      '&:hover': {
        backgroundColor: 'rgba(220, 53, 69, 0.04)',
        borderColor: '#c82333',
        color: '#c82333',
      },
      '&:disabled': {
        borderColor: '#ccc',
        color: '#666',
      },
    },

    // Success Variants
    [buttonVariants.SUCCESS]: {
      backgroundColor: AppColors.SuccessColor || '#28a745',
      color: '#fff',
      border: 'none',
      '&:hover': {
        backgroundColor: '#218838',
      },
      '&:disabled': {
        backgroundColor: '#ccc',
        color: '#666',
      },
    },
    [buttonVariants.SUCCESS_OUTLINE]: {
      backgroundColor: 'transparent',
      color: AppColors.SuccessColor || '#28a745',
      border: `1px solid ${AppColors.SuccessColor || '#28a745'}`,
      '&:hover': {
        backgroundColor: 'rgba(40, 167, 69, 0.04)',
        borderColor: '#218838',
        color: '#218838',
      },
      '&:disabled': {
        borderColor: '#ccc',
        color: '#666',
      },
    },

    // Disabled State
    [buttonVariants.DISABLED]: {
      backgroundColor: '#f5f5f5',
      color: '#666',
      border: 'none',
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },

    // Sizes
    [buttonSizes.FULL_LENGTH]: {
      height: '52px',
      width: '100%',
      borderRadius: '26px',
      fontSize: '16px',
      padding: '0 32px',
    },
    [buttonSizes.LARGE]: {
      height: '48px',
      minWidth: '180px',
      borderRadius: '24px',
      fontSize: '16px',
      padding: '0 28px',
    },
    [buttonSizes.MEDIUM]: {
      height: '40px',
      minWidth: '140px',
      borderRadius: '20px',
      fontSize: '14px',
      padding: '0 24px',
    },
    [buttonSizes.SMALL]: {
      height: '32px',
      minWidth: '100px',
      borderRadius: '16px',
      fontSize: '13px',
      padding: '0 16px',
    },
    [buttonSizes.XSMALL]: {
      height: '28px',
      minWidth: '60px',
      borderRadius: '14px',
      fontSize: '12px',
      padding: '0 12px',
      },
  };

  const iconWithColor = icon 
    ? React.cloneElement(icon, { 
        style: { 
          color: styles[variant].color,
          marginRight: '8px',
          fontSize: styles[size].fontSize,
        } 
      }) 
    : null;

  return (
    <Box sx={{ display: 'inline-block', width: size === buttonSizes.FULL_LENGTH ? '100%' : 'auto' }}>
    <MuiButton
        startIcon={iconWithColor}
        sx={{
          ...styles[variant],
          ...styles[size],
          textTransform: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 500,
          transition: 'all 0.2s ease-in-out',
          boxShadow: 'none',
          '&:hover': {
            ...styles[variant]['&:hover'],
            boxShadow: 'none',
          },
        }}
      onClick={onClick}
      disabled={disabled || variant === buttonVariants.DISABLED}
      {...props}
    >
      {children}
    </MuiButton>
    </Box>
  );
};

export default AppCustomButton;