const AppColors = {
    PrimaryColor: '#ff5be5',
    WhiteColor: '#FFFFFF',
    Grey1Color: '#e1e1e1',
    BorderColor: '#dfdfe0',
    OffWhiteF7: '#f7f7f7',
    OffWhite7F: '#7f7f7f',
    BlackColor: '#000000',
    PrimaryHoverColor: '#c33dad',
    PremiumIconColor: '#feb602',
    AIIconColor: '#2955bb',
    RGBA4Color: '#2955bb',
    CardColorGray: '#EAE6E6',
    DarkBlue: '#000267',
    PrimaryBlue: '#0d6efd',
    
    // Light Chartreuse Green variants
    LightChartreuseGreen: '#B1E900',
    LightChartreuseGreenHover: '#99C700',
    LightChartreuseGreenClick: '#7E9E00',
    LightChartreuseGreenDisable: '#D0E3B0',
    
    // Vivid Fuchsia Pink variants
    VividFuchsiaPink: '#FF5BEF',
    VividFuchsiaPinkHover: '#FF47D9',
    VividFuchsiaPinkClick: '#FF23BA',
    VividFuchsiaPinkDisable: '#FFB0D6',
    
    // Dark Purple variants
    DarkPurple: '#650CC0',
    DarkPurpleHover: '#5320A8',
    DarkPurpleClick: '#411890',
    DarkPurpleDisable: '#A28AC0'
}

export {
    AppColors,
};