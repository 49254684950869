export const AppTexts = {
  DashBoard: "Dashboard",
  DownloadDetails: "Download Details",
  Earnings: "Earnings",
  Orders: "Orders",
  Profile: "Profile",
  Purchases: "Purchases",
  FollowDetails: "Follow Details",
  Resources: "Resources",
  ReleaseForm: "Release Form",
  Wishlist: "Wishlist",
  SelectReleaseForms: "Select Release Forms",
  CreateNewWishlist: "Create a new wishlist",
  AddToExistingWishlist: "Add to existing wishlist",
  Cancel: "Cancel",
  Save: "Save",
  Trending: "Trending",
  BrowseAllCollections: "Browse All Collections",
  RegisterNow: "Register Now",
  ImageLicensing: "Image Licensing",
  JoinOurCommunityToday: "Join our community today",
  SignUpForMonthlyCreativeContentIdeas:
    "Sign-up for monthly creative content ideas.",
  Subscribe: "Subscribe",
  Copyright: "Copyright",
  PICHAStock: "PICHAStock",
  PICHAStockStudio: "PICHAStock Studio",
  AllRightsReserved: "All rights reserved",
  ViewAll: "View All",
  PichaStockInstagramGallery: "PichaStock Instagram Gallery,",
  Invoice: "Invoice",
  From: "From",
  To: "To",
  InvoiceDate: "Invoice Date",
  InvoiceId: "Invoice ID",
  PurchaseKey: "Purchase Key",
  PaymentStatus: "Payment Status",
  PaymentMethod: "Payment Method",
  InvoiceItems: "Invoice Items",
  ProductName: "Product name",
  Price: "Price",
  InvoiceTotals: "Invoice Totals",
  Discount: "Discount",
  Subtotal: "Subtotal",
  TotalPaid: "Total Paid",
  Login: "Login",
  SendRequest: "Send request",
  PopularCategories: "Popular Categories",
  Skip: "Skip",
  SelectAtLeastOneCategory: "Select at least one category",
  Continue: "Continue",
  InviteFriends: "Invite Friends",
  Invite: "Invite",
  PopularContributors: "Popular Contributors",
  FollowAtLeastOneContributor: "Follow at least one contributor",
  Unlike: "Unlike",
  Like: "Like",
  PurchaseNow: "Purchase now",
  DoYouHaveAmazingDesc:
    "Do you have amazing modern images / videos representing different afro communities you'd like to future for sale? just complete the form below and become part of our community.",
  ConfirmYouAre18years: "Please confirm that you are 18 years old",
  ByCreatingAccountIAgreeThat:
    "By creating an account, I agree that, I have read the",
  AgreeToReceiveUpdates:
    "I agree to receive updates about news, programs, and special events from PICHAStock. Please refer to PICHA’s",
  TermsOfService: "Terms of Service",
  PrivacyPolicy: "Privacy Policy",
  Register: "Register",
  AlreadyRegistered: "Already Registered",
  Or: "Or",
  ContinueWithFacebook: "Continue with Facebook",
  VideoUploadingInProgress: "Video uploading in progress",
  Edit: "Edit",
  Contributors: "Contributors",
  ActiveChallenges: "Active Challenges",
  Loading: "Loading ...",
  NoRecordFound: "No Record Found",
  DragAndDrop: "Drag and drop or browse to choose a file",
  Next: "Next",
  ClickHere: "Click here",
  ToAccessTheImages: "to access the images",
  JPEGFormatOnly: "JPEG format only",
  MinimumImageResolutionIs4MP: "Minimum image resolution is 4MP",
  MaximumImageResolutionIs100MP: "Maximum image resolution is 100MP",
  MaximumFileSizeIs45MB: "Maximum file size is 45MB",
  Deadline: "Deadline",
  Organizers: "Organizers",
  ReadyToWinTheChallenge: "Ready to win the challenge",
  Photographers: "Photographers",
  Challenges: "Challenges",
  PastChallenges: "Past Challenges",
  PrivateOnlyViewableByYou: "Private - only viewable by you",
  PublicViewableByAnyone: "Public - viewable by anyone",
  Create: "Create",
  TotalDownloads: "Total Downloads",
  TotalLike: "Total Likes",
  TotalOrders: "Total Orders",
  TotalViews: "Total Views",
  TotalEarnings: "Total Earnings",
  Media: "Media",
  TimeSpan:'Time Span',
  RecommendedForYou:'Recommended for you',
  ShareWithYourCommunity: 'Share this with your Community',
  RecognizablePeople:'Recognizable people or property?',
  Achievement:'Achievement',
  AddNewReleaseForm: 'Add new Release Form',
  LatestPhoto: 'Latest Photo',
  LatestVideo: 'Latest video',
  ShowCase: 'Show case',
  KeywordMsg:'Keyword (Use Comma or enter for next tag)',
  AiTermsCertify : ' I certify that the people or property in this resource are not based on any identifiable person or property. Do not check the box if you have used a real person or existing property to generate your AI resource. If this is the case, please upload a template or image rights authorization.',

  AiNoteTerms : "Note: please consult our requirements before submitting content created using generative artificial intelligence tools. Remember: you can't submit content generated with a prompt that includes the name of one or more other artists.",

  isAIGeneratedText: 'Created using generative AI tools',
  Assets:'Assets',
  Followers: 'Followers',
  LoadMore: 'Load More',
  CheckPeopleAreFictional: 'Please Check People and Property are Fictoinal to continue',
  Error:'Error',
  Share: 'Share',
  SelectImageToContinue:'Select an image / Video to continue',
  WhyJoinUsTitle: 'Why do you want to join PichaStock?',
  ImHereToDownload: "I'm here to download licensed photos and videos",
  DonwloadContent: 'I would like to download licensed content',
  ImHereToShare: "I'm here to share my photos and videos with the world.",
  IWillBecomeContributor: 'I would like to become a contributor',
  JoinUsDescriptionText: 'PICHA believes that original stories can change perceptions. And we are here to do just that. To bring the voices, faces, and stories of modern Afro and Black communities to the world and the brands we serve.',
  ExploreOurCollection: 'Explore our Collection',
  SendUsArief: 'Send us a brief',
  StandardImages:'Standard Images',
  StandardImagesDesc:'Great for basic web use like blogs, articles, brochures, and small prints at around the size of a postcard.',
  LargeImages:'Large Images',
  LargeImagesDesc:'These are big enough for almost any need (i.e., full-page prints, any web need…).',
  Premium: 'Premium',
  PremiumDesc: ' A collection of exceptional visuals, including the breaking barriers collection.',  
  Download: 'Download',
  ByCreatingAnAccount:'By creating an account, I agree that: I have read the',
  IAgreeToReceiveUpdates: "I agree to receive updates about news, programs, and special events from PICHA. Please refer to PICHA’s",
  And: 'and',
  SignIn: 'Sign in',
  RememberMe: 'Remember me',
  ForgotPassword: 'Forgot Password',
  DontHaveAnAccount: "Don't have an account?",
  JoinPichastock: 'Join Pichastock',
  Update: 'Update',
  ViewMyPortfolio: 'View my Portfolio',
  UploadAvatar: 'Upload Avatar',
  UploadCover: 'Upload Cover Image',
  CheckOut: 'Checkout',
  ViewDetails: 'View Details',

  ReadyToChangeVisualNarrative: "Ready to change the visual narrative?",
  ModernImagesDesc: "Do you have amazing modern images / videos representing different Afro communities you'd like to feature for sale?",
  JustRegister: "Just Register",
  BecomePartOfCommunity: "and become part of our community",
  
  WhyChooseUs: "WHY CHOOSE US",
  WhyJoinPicha: "Why Join PICHA?",
  PichaDescription: "PICHA is a visual content provider founded on the realization that the perceptions of Afro-descendants in the world are not always the reality of Afrocentric communities in the world.",
  
  MakeADifference: "Make a Difference",
  MakeADifferenceDesc: "It's time black communities were represented authentically in the media. We need visual storytellers like you to help us break stereotypes. #RepresentationMatters",
  MakeSomeMoney: "Make some money",
  MakeMoneyDesc: "Every image uploaded will earn you a commission every time it sells for as long as it is a part of the PICHA library. Grow your stock image portfolio and potential passive income.",
  MakeNameForYourself: "Make a name for yourself",
  MakeNameDesc: "We regularly credit and feature our contributors on social media and with our clients. Share your PICHA contributor profile to build credibility.",
  
  JoinInOn: "Join in on",
  SomethingBig: "something big",
  Contributors: "CONTRIBUTORS",
  DownloadsPerDay: "DOWNLOADS/DAY",
  Visits: "VISITS",
  
  SubmissionRequirements: "SUBMISSION REQUIREMENTS",
  SubmissionGuidelines: "Submission Guidelines",
  GuidelinesDesc: "Help our clients tell more authentic visual narratives that connect with their markets. Join the movement as we aim to provide the leading curated collection of modern Afrocentric stock images online.",
  
  ImageSubmissionGuidelines: "Image Submission Guidelines",
  ImageGuidelinesList: [
    "Submit images in JPEG format",
    "Minimum image resolution: 4 MP",
    "Tip: submit the maximum file size that your camera can produce.",
    "No watermarks"
  ],
  
  VideoSubmissionGuidelines: "Video Submission Guidelines",
  VideoGuidelinesList: [
    "Minimum resolution of 1920 x 1080",
    "Clips should be between 5-30 seconds long",
    "Export trimmed footage as H.264 .MOV files",
    "Please remove all audio before exporting"
  ],
  
  JoinAsPichaContributor: "Join now as a PichaStock contributor.",
  WorldAwaitsCreativity: "The world awaits your creativity",
  BecomeContributor: "Become a contributor",
  
  FrequentlyAskedQuestions: "Frequently Asked Questions",
  FAQDesc: "Find answers to common questions about becoming a contributor",
  
  FAQs: [
    {
      question: "What are the technical requirements for submissions?",
      answer: "Submit images in JPEG format. Minimum image resolution: 4 MP. No watermarks. Submit the maximum file size that your camera can produce."
    },
    {
      question: "What are releases and when are they needed?",
      answer: "Model releases are required whenever a person is recognizable in your image. Property release forms are required for unique and recognizable locations. Available for download via your contributor portal."
    },
    {
      question: "I'm not in Africa, can I still be a contributor?",
      answer: "Yes – we're looking for images that tell the stories of modern black people worldwide, especially in the UK, USA, and Brazil."
    },
    {
      question: "How much will I get paid?",
      answer: "Contributors make 50% commission on every sale. The selling price depends on factors like resolution and licenses. Higher commissions for footage and premium collections."
    },
    {
      question: "Do you accept mobile phone submissions?",
      answer: "Yes, you can submit phone images. They will be curated against the same requirements as regular submissions."
    },
    {
      question: "What type of images should I shoot?",
      answer: "We need images depicting everyday life of African people. All images should be modern, diverse, and authentic."
    },
    {
      question: "Resources",
      answer: "Check our blog, Youtube page, and resources page for guidelines and release forms. Email info@pichastock.com for questions."
    }
  ],
  
  // Submission Requirements Section
  RequirementsTitle: "Requirements",
  RequirementsText: "Anyone can become a contributor as long as they are over 18 years old. You must own the copyright to the photos you will submit. All visuals you will submit have to be created by you.",
  CommercialPhotoText: "Customers are looking for high-quality images in all kinds of areas, so we are looking for creatives with a strong understanding of commercial photography.",
  
  // Call to Artists Section
  CallToArtistsTitle: "What are we looking for in a call to Artists?",
  CallToArtistsText: "We are looking for creative and talented artists ready to add something unique to our collection. PICHA`s mission is to tell the stories of Afro communities worldwide. We are looking for committed artists who can understand visual storytelling.",
  
  // Content Needs Section
  ContentNeedsTitle: "PICHA's content need",
  ContentNeedsText: "Business and Technology, People, Lifestyle, Underrepresented groups, Real people doing real things, Conceptualization of still life, and abstract elements.",
  
  // Additional Info
  ReviewWebsiteText: "Before applying, make sure to go over our website, and our collections to help you get a feel of the type of visuals we have.",
  QuestionsText: "If you have any questions, please refer to our FAQ or send us a question.",
  
  // Guidelines Section
  GuidelinesTitle: "Submission Guidelines",
  GuidelinesDescription: "Help our clients tell more authentic visual narratives that connect with their markets. Join the movement as we aim to provide the leading curated collection of modern Afrocentric stock images online.",
  ImageGuidelinesTitle: "Image Submission Guidelines",
  VideoGuidelinesTitle: "Video Submission Guidelines",
  
  // FAQ Section
  FAQTitle: "Frequently Asked Questions",
  FAQDescription: "Find answers to common questions about becoming a contributor",
  
  // Core Vision Text
  CoreVisionText: "PICHA is a visual content provider founded on the realization that the perceptions of Afro-descendants in the world are not always the reality of Afrocentric communities in the world.",
  
  // Guidelines Arrays
  ImageGuidelines: [
    "Submit images in JPEG format",
    "Minimum image resolution: 4 MP",
    "Tip: submit the maximum file size that your camera can produce.",
    "No watermarks"
  ],
  VideoGuidelines: [
    "Minimum resolution of 1920 x 1080",
    "Clips should be between 5-30 seconds long",
    "Export trimmed footage as H.264 .MOV files",
    "Please remove all audio before exporting"
  ],
  
  // FAQ Items
  FAQItems: [
    {
      question: "What are the technical requirements for submissions?",
      answer: "Submit images in JPEG format. Minimum image resolution: 4 MP. No watermarks. Submit the maximum file size that your camera can produce."
    },
    {
      question: "What are releases and when are they needed?",
      answer: "Model releases are required whenever a person is recognizable in your image. Property release forms are required for unique and recognizable locations. Available for download via your contributor portal."
    },
    {
      question: "I'm not in Africa, can I still be a contributor?",
      answer: "Yes – we're looking for images that tell the stories of modern black people worldwide, especially in the UK, USA, and Brazil."
    },
    {
      question: "How much will I get paid?",
      answer: "Contributors make 50% commission on every sale. The selling price depends on factors like resolution and licenses. Higher commissions for footage and premium collections."
    },
    {
      question: "Do you accept mobile phone submissions?",
      answer: "Yes, you can submit phone images. They will be curated against the same requirements as regular submissions."
    },
    {
      question: "What type of images should I shoot?",
      answer: "We need images depicting everyday life of African people. All images should be modern, diverse, and authentic."
    },
    {
      question: "Resources",
      answer: "Check our blog, Youtube page, and resources page for guidelines and release forms. Email info@pichastock.com for questions."
    }
  ],
};