import React, { useCallback, useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faMagnifyingGlass,
  faRightFromBracket,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { HomeConstants } from "../constants/HomePageConstants";
import Storage from "../services/local_storage/localStorage";
import { onCallIsAuth } from "../redux/slices/isAuth";
import instance from "../services/apiServices/Api";
import { isLoggedInMsg, errorMessage } from "../helpers/Message";
import SimpleBackdrop from "./Backdrop";
import userLogo from "../assets/user-logo.png";
import { getDataRedux } from "../redux/slices/cartSlice";
import { getMenuRedux } from "../redux/slices/menuSlice";
import { useFormik } from "formik";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { AppColors } from "../constants/AppColors";
import CartModal from "./CartModal";
import { Box, Button, Typography } from "@mui/material";
import FreeTrialBanner from "./FreeTrialBanner";
import "./NavbarCustom.css";

function NavbarCustom({ homecss }) {
  const userData = Storage.getUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const { value } = useSelector((item) => item?.isAuth);
  const dispatch = useDispatch();
  const token = Storage.getToken("token");
  const { CustomNavbarData } = HomeConstants;
  const themedata = Storage.getTheme("theme");
  const [darkTheme, setDarkTheme] = useState(
    themedata === "dark" ? true : false
  );
  const [tooltip] = useState(true);
  const [toggleClass, setToggleClass] = useState(false);
  const [loading, SetLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const cartData = useSelector((state) => state.addToCart.dataOfCart);
  const HeaderReduxData = useSelector((state) => state.menus.dataOfHeader);
  const navbarData =
    HeaderReduxData?.items?.length > 0
      ? HeaderReduxData
      : Storage.getHeaderData();
  const changeTheme = () => setDarkTheme(!darkTheme);

  const [isCartModalOpen, setCartModalOpen] = useState(false);
  const toggleCartModal = () => setCartModalOpen(!isCartModalOpen);

  const [showBanner, setShowBanner] = useState(false);

  const isSticky = (e) => {
    const header = document?.getElementById("navbar");
    const scrollTop = window?.scrollY;
    const shouldBeSticky = scrollTop >= 150.8;
    if (shouldBeSticky) {
      header?.classList?.add("sticky-header");
      setShowBanner(true);
      document.body.classList.add('navbar-sticky');
    } else {
      header?.classList?.remove("sticky-header");
      setShowBanner(false);
      document.body.classList.remove('navbar-sticky');
    }
  };

  const DarkMode = useCallback(() => {
    Storage.SetTheme(darkTheme ? "dark" : "light");
    const search = document.getElementsByTagName("body")[0];
    search.classList[darkTheme ? "add" : "remove"]("darkMode--on");
  }, [darkTheme]);

  useEffect(() => {
    // Facebook pixel
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    window.fbq("init", "851847661593244");
    window.fbq("track", "PageView");

    // Add Roll Code
    (function (w, d, e, o, a) {
      w.__adroll_loaded = true;
      w.adroll = w.adroll || [];
      w.adroll.f = ["setProperties", "identify", "track"];
      var roundtripUrl =
        "https://s.adroll.com/j/" + window.adroll_adv_id + "/roundtrip.js";
      for (a = 0; a < w.adroll.f.length; a++) {
        w.adroll[w.adroll.f[a]] =
          w.adroll[w.adroll.f[a]] ||
          (function (n) {
            return function () {
              w.adroll.push([n, arguments]);
            };
          })(w.adroll.f[a]);
      }
      e = d.createElement("script");
      o = d.getElementsByTagName("script")[0];
      e.async = 1;
      e.src = roundtripUrl;
      o.parentNode.insertBefore(e, o);
    })(window, document);
    window.adroll.track("pageView");

    // Google Analytics code
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "UA-1573128-33");

    //

    setUserRole(userData?.role_label);
    DarkMode();
    if (location?.pathname !== "/checkout" && location?.pathname !== "/login") {
      dispatch(getDataRedux());
    }
    dispatch(getMenuRedux());
    window.addEventListener("scroll", isSticky);

    return () => {
      window.removeEventListener("scroll", isSticky);
    };
    // eslint-disable-next-line
  }, [DarkMode, token, dispatch, location]);

  const formik = useFormik({
    initialValues: {
      search_text: "",
    },
    onSubmit: (values) => {
      let data = {
        type: "both",
        search_text: values.search_text,
        current_page: 1,
        per_page: 20,
        order_by: "newness_asc",
        category_slug: "",
      };
      Storage.removeData("searchfilter");
      navigate("/SearchResult", {
        state: data,
      });
    },
  });

  const toggleButton = () => {
    const humburger = document.getElementsByTagName("body")[0];
    humburger.classList.toggle("open-menu");
  };

  const toggleButton2 = () => {
    // const humburger = document.getElementsByTagName("body")[0];
    // humburger.classList.remove("open-menu");
  };

  const handleDropdownClick = (id) => {
    document.getElementById(id).classList.toggle("openDropdown");
  };

  const handleClick = (isAuth) => {
    if (isAuth) {
      SetLoading(true);
      instance
        .get("auth/logout", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          SetLoading(false);
          isLoggedInMsg(res.data.message);
          dispatch(onCallIsAuth(false));
          Storage.removeData("token");
          Storage.removeData("userData");
          Storage.removeData("preferencestep");
          window?.FB?.logout();
          navigate("/login");
          window?.location?.reload();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            SetLoading(false);
            // isLoggedInMsg(res.data.message)
            dispatch(onCallIsAuth(false));
            Storage.removeData("token");
            Storage.removeData("userData");
            navigate("/login");
          } else {
            errorMessage("Error!", error.response.data.message);
            SetLoading(false);
          }
        });
    } else {
      navigate("/login");
    }
  };

  const dropDownMenus = (item, i) => {
    if (item?.items?.length > 0) {
      return (
        <li
          id={item?.name}
          onClick={() => handleDropdownClick(item?.name)}
          className={token && item?.hide_on_login === 1 ? "d-none" : ""}
          key={i}
        >
          <NavLink className="navbar--menus-dropdown" to={"#"}>
            {item?.name}
          </NavLink>
          {item?.items?.length > 0 && (
            <ul className="navbar--submenu">
              <li className="navbar--submenu-content">
                <div className="navbar--submenu-mega">
                  <ul>
                    {item?.items.map((item, i) => dropDownMenus(item, i))}
                  </ul>
                </div>
              </li>
            </ul>
          )}
        </li>
      );
    }
    return (
      <li onClick={() => toggleButton2} key={i}>
        {
          <NavLink
            to={`/${item?.slug}`}
            className={token && item?.hide_on_login === 1 ? "d-none" : ""}
            onClick={toggleButton2}
          >
            {item?.name}
          </NavLink>
        }
      </li>
    );
  };

  return (
    <>
      {loading && <SimpleBackdrop />}

      {!loading && (
        <>
          <div className={`header-wrapper ${showBanner ? 'has-banner' : ''}`}>
            <div className="sticky-banner-container">
              {showBanner && <FreeTrialBanner isSticky={isSticky} />}
            </div>
            <section id="navbar" className={`navbar ${homecss ? homecss : ""}`}>
              <Link to="/" className="sitelogo">
                <img
                  src={
                    "https://pichastockv2-prod-resize-media.s3.amazonaws.com/assets/logo.png"
                  }
                  alt="Pichastock"
                />
              </Link>

              <div className="header-search-bar">
                <div className="navbar--searchbar-outer">
                  <form
                    className="navbar--searchbar"
                    onSubmit={formik.handleSubmit}
                  >
                    <input
                      name="search_text"
                      type="text"
                      placeholder="Start the search by typing a keyword"
                      {...formik.getFieldProps("search_text")}
                    />
                    <button type="submit" className="search-action">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="search_icon"
                      />
                    </button>
                  </form>
                </div>
              </div>

              <button
                id="humburger"
                onClick={toggleButton}
                className="humburger-icon"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>

              <div className="navbar--menus">
                <ul className="main-menu">
                  {navbarData?.items?.length > 0 &&
                    navbarData?.items.map((item, i) => dropDownMenus(item, i))}
                  {CustomNavbarData.length > 0 &&
                    CustomNavbarData.map((item, i) => {
                      if ("icon" in item) {
                        return (
                          <li
                            key={i}
                            id={item?.name}
                            onClick={() => handleDropdownClick(item?.name)}
                            className=""
                          >
                            {item?.name === "login" ? (
                              value ? (
                                <>
                                  <NavLink
                                    className="navbar--menus-dropdown"
                                    to={"#"}
                                  >
                                    <img
                                      src={userData.avatar ?? userLogo}
                                      alt=""
                                      className="profile-avtar"
                                      style={{ border: `1px solid${AppColors.Grey1Color}` }}
                                    />
                                  </NavLink>
                                  <ul className="navbar--submenu">
                                    <li className="navbar--submenu-content">
                                      <div className="navbar--submenu-mega">
                                        <ul>
                                          {item?.dropDownMenu.map((item, i) => (
                                            <li
                                              key={i}
                                              onClick={toggleButton2}
                                              className={
                                                userRole !== "Contributor" &&
                                                item?.name === "Challenges"
                                                  ? "hide_cls"
                                                  : ""
                                              }
                                            >
                                              {item?.name === "Logout" ? (
                                                <NavLink
                                                  to={item?.link}
                                                  onClick={handleClick}
                                                >
                                                  {item?.name}
                                                  <FontAwesomeIcon
                                                    icon={faRightFromBracket}
                                                  />
                                                </NavLink>
                                              ) : (
                                                <NavLink
                                                  to={
                                                    item?.link ===
                                                    "/contributor-dashboard"
                                                      ? `/${userRole?.toLowerCase()}-dashboard`
                                                      : item?.link
                                                  }
                                                >
                                                  {item?.name}
                                                </NavLink>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </>
                              ) : (
                                <NavLink to={"/login"} onClick={toggleButton2}>
                                  <FontAwesomeIcon icon={faUserAlt} />
                                  &nbsp;Login
                                </NavLink>
                              )
                            ) : (
                              <>
                                <NavLink to={"#"} onClick={toggleCartModal}>
                                  <FontAwesomeIcon icon={faCartShopping} />

                                  {cartData?.cart_items && cartData.cart_items.length > 0 && (
                                    <span className="cart-count">
                                      {cartData.cart_items.length}
                                    </span>
                                  )}
                                </NavLink>
                                <CartModal open={isCartModalOpen} handleClose={toggleCartModal} />
                              </>
                            )}
                          </li>
                        );
                      }
                      return null;
                    })}
                </ul>

                <div
                  // hidden={location.pathname === "/SearchResult" && true}
                  className={
                    toggleClass
                      ? `search-field-outer open-search`
                      : `search-field-outer`
                  }
                >
                  <span
                    onClick={() => setToggleClass(!toggleClass)}
                    className="search-menu"
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="search_icon"
                    />
                  </span>
                  <div className="navbar--searchbar-outer">
                    <form
                      className="navbar--searchbar"
                      onSubmit={formik.handleSubmit}
                    >
                      <input
                        name="search_text"
                        type="text"
                        placeholder="Fashion"
                        {...formik.getFieldProps("search_text")}
                      />
                      <button type="submit" className="search-action">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="search_icon"
                        />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div onClick={changeTheme} className={`dark-mode--btn`}>
                {tooltip && <Tooltip id="my-tooltip" place="left" />}
                <button
                  className="dark-mode--btn-white"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Day/Night"
                ></button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default NavbarCustom;
